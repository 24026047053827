@font-face {
    font-family: 'One Dot';
    src: url('./resources/OneDot.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Pizza Press Reverse Shadow';
    src: url('./resources/PizzaPressReverseShadow.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Pizza Press Fill';
    src: url('./resources/PizzaPressFill.woff') format('woff');
    /* font-weight: 300;
    font-style: normal;
    font-display: swap; */
}

@font-face {
    font-family: 'Agbalumo';
    src: url('./resources/Agbalumo-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}


